.list-branch {
  padding-top: 100px;
  margin: auto;
}

.title-branch {
  font-size: 24px;
  font-weight: 600;
  color: #001133;
  text-align: left;
}

.type-branch {
  font-size: 16px;
  font-weight: 600;
  color: #001133;
  text-align: left;
}

.address-branch {
  font-size: 16px;
  font-weight: 300;
  color: #001133;
  text-align: left;
}

.map-wrapper {
  position: relative;
  width: 1280px;
  height: 499px;
  margin: auto;
  padding-top: 80px;
}

#map {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
}

.marker {
  background-image: url("~/public/images/marker.png");
  background-size: cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.popups {
  min-width: 300px;
}

#canvas {
  width: 100%;
}

.maps {
  padding: 48px 0 0px 0;
  justify-content: center;
  position: relative;
  left: 0;
  top: 0;
}

.map-wrapper {
  position: relative;
  width: 1280px;
  height: 550px;
  margin: auto;
  padding-top: 80px;
}

@media screen and (max-width: 960px) {
  .list-branch {
    margin-top: -90px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: none;
    list-style: none;
    text-align: center;
    justify-content: center;
  }
}
