.hero-container-tkdn-mobile {
  background: url("~/public/images/TKDN/Hero TKDN.png") center center/cover
    no-repeat;
  min-height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  margin-top: -80px;
}

.hero-container-tkdn-mobile > h1 {
  color: white;
  font-size: 64px;
  /* margin-top: 100px; */
  padding-left: 240px;
  padding-right: 240px;
  text-align: center;
  letter-spacing: 0.1rem;
  max-width: 1340px;
}

.hero-container-tkdn-mobile > p {
  color: white;
  font-size: 24px;
  margin-top: 30px;
  padding: 0 150px 50px 150px;
  text-align: center;
  letter-spacing: 0.1rem;
  max-width: 1340px;
}

.hero-btns {
  margin-top: 32px;
  align-items: center;
}

.hero-btns .btn {
  margin: 16px 16px 16px 0;
}

@media screen and (max-width: 960px) {
  .hero-container-tkdn-mobile > h1 {
    font-size: 34px;
    /* margin-top: -150px; */
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0.1rem;
  }

  .hero-container-tkdn-mobile > p {
    font-size: 18px;
    padding: 0 15px 15px 15px;

    letter-spacing: 0.1rem;
  }
}

@media screen and (max-width: 768px) {
  .hero-container-tkdn-mobile > h1 {
    font-size: 28px;
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0.1rem;
  }

  .hero-container-tkdn-mobile > p {
    font-size: 14px;
    padding: 0 15px 15px 15px;
    letter-spacing: 0.1rem;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
    align-items: center;
  }

  .btn {
    width: 100%;
  }
}
