.vvb-tab-container {
  padding: 0 60px 0 60px;
  width: 100%;
}

.about-text {
  text-align: left;
  padding: 0;
  margin: 0;
}

.about-desc-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  flex-wrap: wrap;
}

.tkdn-desc-flex {
  flex: 1;
}

.vvb-title {
  text-align: left;
  padding-top: 24px;
}

.about-header {
  display: flex;
  flex-direction: column;
}

.tkdn-subtitle {
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 38.4px */
  letter-spacing: 0.72px;
  margin: 0;
}

.service-img {
  height: 100%;
  width: 100%;
  margin: auto
}

.service-title {
  font-size: 20px;
  font-weight: 600;
  padding: 5px 10px 0px 10px;
}

.service-desc {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  padding: 0 10px 0 10px;
}

.scope {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  padding: 0 10px 0 0;
}

.service-subservice-title {
  font-size: 16px;
  color: #335CA5;
  font-weight: 600;
  text-align: left;
  padding: 0 10px 0 10px;
}

.service-list {
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  padding: 0 10px 0px 5px;
  list-style-position: outside;
}

@media only screen and (max-width: 960px) {
  .vvb-container-about {
    padding: 40px;
    gap: 24px;
  }

  .service-title {
    text-align: left;
    padding-top: 24px;
  }

  .list-scope{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .vvb-tab-container {
    padding: 0;
  }
  .vvb-title {
    font-size: 24px;
  }

  .tkdn-subtitle {
    font-size: 18px;
  }

  .about-desc-container {
    flex-direction: column;
  }
}
