.flow-img-full {
  width: 100%;
}

.flow-img-container {
  width: 100%;
  height: 100%;
}

.flow-img-container-cut {
  height: 40vh;
  overflow: hidden;
}

.gradient {
  height: 50px;
  width: 100%;
  background: linear-gradient(rgb(242, 242, 242, 0), rgb(242, 242, 242, 1));
  margin-top: -74px;
}

@media screen and (max-width: 960px) {
  .flow-img-container {
    width: 100%;
  }
}
