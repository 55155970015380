.appeal-img {
  width: 100%;
  padding: 0;
  display: block;
  margin: 50px auto 0 auto;
}

.appeal-desc {
  padding: 0 0 20px 0;
  text-align: left;
}