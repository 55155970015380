.title-license {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  padding: 20px 0 0 0;
}

.title-desc {
  text-align: left;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 14px;
}

.left-col {
  padding: 0;
  border-radius: 0%;
}

.left-col-certif {
  padding: 0;
  border-radius: 0%;
}

.nav-left {
  background-color: #f2f2f2;
  text-align: left;
  padding: 0;
  border-radius: 0%;
}

.name-tab {
  color: black;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  border-radius: none;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  border-radius: 0%;
  vertical-align: middle;
  align-items: center;
  justify-content: left;
}

.name-tab:hover {
  color: black;
}

.name-tab.active {
  background-color: #e0e0e0;
  border-left: 8px solid #3a7ae3;
  border-bottom: none;
  border-right: none;
  border-top: none;
  font-weight: 600;
  border-radius: 0%;
}

.nav_pills {
  background-color: #f2f2f2;
  width: 100%;
  height: 198px;
  margin: auto;
  border-radius: 0%;
}

.nav_pills_certif {
  background-color: #f2f2f2;
  width: 100%;
  height: 252px;
  margin: auto;
  border-radius: 0%;
}

.nav_pills:hover {
  background-color: #e0e0e0;
  border-radius: 0%;
}

.nav_pills_certif:hover {
  background-color: #e0e0e0;
  border-radius: 0%;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: #e0e0e0;
  border-radius: 0%;
}

.right-col {
  padding: 0;
  margin-left: 0;
  flex-wrap: nowrap;
  text-align: left;
  justify-content: left;
  background-color: #e0e0e0;
}

.content-right {
  justify-content: left;
  text-align: left;
  margin: 0;
}

.content-right.tab-content {
  width: 100%;
  padding: 20px;
  justify-content: left;
  align-items: stretch;
  display: flex;
  flex-wrap: nowrap;
}

.list-content {
  padding-left: 25px;
  padding-top: 10px;
}

.name-tab-certif {
  color: black;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  border-radius: none;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  border-radius: 0%;
  vertical-align: middle;
  align-items: center;
}

.name-tab-certif:hover {
  color: black;
}

.name-tab-certif.active {
  background-color: #e0e0e0;
  border-left: 8px solid #3a7ae3;
  border-bottom: none;
  border-right: none;
  border-top: none;
  font-weight: 600;
  border-radius: 0%;
}

.padding-accreditation {
  padding-top: 80px;
}

@media screen and (max-width: 960px) {
  .title-license {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    padding: 20px 0 0 0;
  }

  .title-desc {
    text-align: left;
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 14px;
  }

  .custom-select {
    margin: auto;
    top: 0;
    background-color: #e0e0e0;
  }

  .dropdown-container {
    width: 100%;
  }

  .licenses-dropdown-container {
    padding: 10px 10px 40px 10px;
  }

  .title-dropdown {
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    padding: 25px 5px 0 10px;
  }

  .list-dropdown {
    text-align: left;
    font-size: 14px;
    padding: 0 5px 0 0;
    margin-left: 10px;
  }

  .list-content {
    padding-bottom: 10px;
  }

  .padding-accreditation {
    margin-top: -30px;
    padding-top: 0px;
  }

  .title-certif {
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    padding: 0 5px 0 10px;
  }

  .certif-desc {
    font-size: 14px;
    padding: 0 5px 0 10px;
    text-align: left;
  }

  .certif-list {
    text-align: left;
    font-size: 14px;
    padding: 0 5px 0 10px;
  }
}
