.submission-type-container {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding-left: 16px;
  padding-bottom: 16px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.input-container {
  width: 100%;
}

@media only screen and (max-width: 960px) {
  .form-container {
    padding-left: 16px;
    width: 100%;
  }
}
