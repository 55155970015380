.nested-scope {
    margin-left: 2rem;
}

.stick-to-top {
    position: sticky;
    top: 79px;
    padding: 8px;
    width: 100%;
    background-color: white;
    z-index: 1;
}

.container-import {
    padding: 80px 80px 80px 80px;
    background-color: white;
    max-width: 1340px;
    margin: auto;
}

.content-img {
    width: 100%;
    height: 203px;
}

.subtitle-vpti {
    font-weight: 600;
    font-size: 16px;
    text-align: left;
}

.permendag-main {
    font-size: 16px;
    font-weight: 600;
    color: #3A7AE3;
    cursor: pointer;
}

.permendag-main:hover {
    color: #2f50a3;
    text-decoration: none;
}

.list-rev {
    line-height: 180%;
    padding: 0 0 0 20px;
}

.rev {
    color: black;
    font-size: 16px;
    font-weight: 300;
    padding: 5px 0 0 0;
}

.rev:hover {
    text-decoration: none;
}

.kepmendag-main {
    font-size: 16px;
    font-weight: 600;
    color: #3A7AE3;
    cursor: pointer;
    margin: 0 0 10px 0;
}

.kepmendag-main:hover {
    color: #2f50a3;
    text-decoration: none;
}

.subtitle-ruang-lingkup {
    color: black;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
}

.list-rev-rl {
    line-height: 180%;
    color: black;
    font-size: 16px;
    font-weight: 300;
    padding: 0 0 0 20px;
}

.contact-content {
    font-size: 16px;
    font-weight: 300;
    color: black;
    margin: 0px;
    text-align: left;
}

.catatan-content {
    color: black;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    padding-top: 10px;
}

.rectangle {
  background-color: #BEBEBE;
  padding: 10px 20px 10px 20px;
}

.left-col-vpti {
    padding: 0;
    border-radius: 0%;

    height: 1350px;
    overflow-y: scroll;
}

.nav-left-vpti {
    background-color: #F2F2F2;
    text-align: left;
    padding: 0;
    border-radius: 0%;
}

.nav_pills-vpti {
    background-color: #F2F2F2;
    width: 100%;
    height: 140px;
    margin: auto;
    border-radius: 0%;
    color: black
}

.nav_pills-vpti:hover{
    background-color: #E0E0E0;
    border-radius: 0%;
}

.name-tab-vpti {
    color: black;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    align-content: center;
    display: flow;
    height: 100%;
    border-radius: none;
    border-left: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    border-top: 1px solid #E0E0E0;
    border-radius: 0%;
}

.name-tab-vpti:hover{
    color: black;
}

.name-tab-vpti.active {
    background-color: #E0E0E0;
    border-left: 8px solid #3A7AE3;
    border-bottom: none;
    border-right: none;
    border-top: none;
    font-weight: 600;
    border-radius: 0%;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: black;
    background-color: #E0E0E0;
    border-radius: 0%;
}

.right-col-vpti {
    padding: 0;
    margin-left: 0;
    flex-wrap: nowrap;
    text-align: left;
    justify-content: left;
    background-color: #E0E0E0;

    height: 1350px;
    overflow-y: scroll;
}

.grid-clients {
    padding: 20px 0 20px 0;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 30px;
    list-style: none;
    text-align: center;
    justify-content: center;
    margin: auto;
}

.clients-vpti {
    padding: 10px;
    max-width: 200px;
    max-height: 150px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.clients-vpti-lv {
    padding: 10px;
    max-width: 130px;
    max-height: 130px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.clients-vpti-hsp {
    padding: 10px;
    max-width: 100px;
    max-height: 100px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 960px) {
    .container-import {
        padding: 80px 40px 40px 40px;
    }

    .container-vpti-mobile {
        padding: 0 10px 10px 10px;
    }

    .content-img {
        padding: 10px 0 0 0;
        width: 100%;
        height: 100%;
    }

    .subtitle-vpti {
        font-size: 16px;
        padding: 20px 0 0 0;
    }

    .permendag-main {
        font-size: 14px;
    }

    .list-rev {
        font-size: 14px;
    }

    .rev {
        font-size: 14px;
    }

    .kepmendag-main {
        font-size: 14px;
    }

    .subtitle-ruang-lingkup {
        font-size: 14px;
    }

    .list-rev-rl {
        font-size: 14px;
        margin-top: -10px;
    }

    .catatan-content {
        font-size: 14px;
    }

    .rectangle {
        background-color: #E0E0E0;
    }

    .grid-clients {
        padding: 20px 0 0 0;
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 20px;
        list-style: none;
        text-align: center;
        justify-content: center;
        margin: auto;
    }
    
    .clients-vpti {
        padding: 2px;
        max-width: 120px;
        max-height: 200px;
        margin: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    
    .clients-vpti-lv {
        padding: 2px;
        max-width: 120px;
        max-height: 120px;
        margin: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}