.lvv-subtitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 38.4px */
  letter-spacing: 0.72px;
  margin: 30px 0 0 0;
  text-align: left;
}

.container-about-img {
  padding: 20px 0 0 0;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 80px;
  list-style: none;
  text-align: center;
  justify-content: center;
  margin: auto;
}

.benefit-img {
  padding: 0;
  /* min-width: 140px; */
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 960px) { 
  .container-about-img {
    grid-template-columns: repeat(1, auto);

  }
}