.our-company-container {
    padding: 80px 80px 40px 80px;
    margin: auto;
    max-width: 1340px;
    text-align: center;
}

@media screen and (max-width: 960px){
    .our-company-container {
        padding: 10px 50px 40px 50px;
    }

    h1 {
        text-align: center;
        letter-spacing: 1px;
        font-size: 28px;
    }

    .custom-select {
        margin: auto;
        top: 0;
        background-color: #E0E0E0;
    }

    .dropdown-container {
        width: 100%;
    }
}