.vvb-container-about {
    padding: 80px;
    max-width: 1340px;
    margin: auto;
    gap: 28px;
    display: flex;
    flex-direction: column;
  }
  
  .about-text {
    text-align: left;
    padding: 0;
    margin: 0;
  }
  
  .about-desc-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 100%;
    flex-wrap: wrap;
  }
  
  .tkdn-desc-flex {
    flex: 1;
  }
  
  .vvb-title {
    text-align: left;
    padding-top: 24px;
  }
  
  .about-header {
    display: flex;
    flex-direction: column;
  }
  
  .tkdn-subtitle {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 38.4px */
    letter-spacing: 0.72px;
    margin: 0;
  }
  
  @media only screen and (max-width: 960px) {
    .vvb-container-about {
      padding: 40px;
      gap: 24px;
    }
  
    .vvb-title {
      font-size: 24px;
    }
  
    .tkdn-subtitle {
      font-size: 18px;
    }
  
    .about-desc-container {
      flex-direction: column;
    }
  }
  