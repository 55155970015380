.card-tkdn {
  display: flex;
  flex: 1 0 30%;
  align-items: flex-start;
  background: #fff;
}

.card-text-tkdn {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  width: 100px;
  hyphens: auto;
}

.card-img-tkdn {
  width: 126px;
  height: 168px;
}

.content-assessment-container {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
}

.tkdn-subtitle-2 {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.48px;
  margin: 0;
}

.tkdn-bumper-card {
  display: flex;
  flex: 1 0 30%;
}

@media only screen and (max-width: 960px) {
  .card-tkdn {
    width: 100%;
    max-width: none;
  }
}
