.section-certification {
    max-width: 1340px;
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 40px;
    letter-spacing: 0.1rem;
}

.border-title-certification {
    border-bottom: 4px solid #3A7AE3;
    width: 60px;
    position: absolute;
    left: 49.4%;
}

.title-certification {
    padding-top: 28px;
    padding-bottom: 20px;
    justify-content: center;
}

h1 {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
}

/* p {
    padding-top: 28px;
    text-align: center;
} */

.img-kan {
    margin: auto;
}

.img-bav {
    margin: auto;
}

.col-img {
    margin: auto;
    padding: auto;
    align-items: center;
    max-width: fit-content;

}

@media screen and (max-width: 960px) {
    /* .border-title-certification {
        border-top: 4px solid #3A7AE3;
        width: 60px;
        position: absolute;
        left: 3%;
    } */

    .img-kan {
        max-height: 180px;
    }

    .img-bav {
        max-width: 250px;
    }

    .row-img {
        margin: auto;
        padding-bottom: 28px;
    }

}