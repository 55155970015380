.footer--light {
    margin: auto;
    padding: 20px 80px 40px 80px;
    max-width: 1340px;
}

.row-footer {
    padding: 20px 0 0 0;
}

.footer-logo {
    max-width: 250px;
}

.footer-widget-title {
    font-size: 18px;
    font-weight: 600;
    color: white;
    margin-bottom: 15px;
    text-align: left;
    padding-top: 0;

}

.footer-widget-content {
    font-size: 16px;
    font-weight: 300;
    color: white;
    text-align: left;
    padding-top: 0;
}

.footer-widget-misc {
    font-size: 16px;
    font-weight: 300;
    color: white;
    text-align: left;
    padding-top: 0;
}

.footer-widget-misc:hover {
    color: white;
}

.copywrite {
    margin: auto;
    padding: 10px 0 0 0;
    font-size: 14px;
    color: white;
}

.fas {
    color: white
}

@media screen and (max-width: 960px) {
    .footer-logo {
        max-width: 180px;
        margin-bottom: 2rem;
    }

    .footer-widget-title {
        font-size: 16px;
    }

    .footer-widget-title-hq {
        font-size: 16px;
        font-weight: 600;
        color: white
    }

    .footer-widget-content {
        font-size: 14px;
        font-weight: 300;
        /* color: black; */
    }
    
    .footer-widget-content-hq {
        font-size: 14px;
        font-weight: 300;
        color: white;
        text-align: left;
        width: 100%;
        padding-top: 0;
    }
    
    .footer-widget-hq-contact {
        /* padding-top: 10px; */
        text-align: left;
        /* margin-bottom: 0; */
        color: white;
        font-size: 14px;
        font-weight: 300;
    }

    .copywrite {
        margin-top: 30px;
    }
}