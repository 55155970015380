.tkdn-provision-card {
  display: flex;
  padding: 12px 24px;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;

  min-height: 135px;

  background: var(--Gray-6, #f2f2f2);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.tkdn-provision-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.tkdn-chip {
  color: var(--Black, #013);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 16px */
  letter-spacing: 0.3px;
  text-transform: uppercase;

  display: flex;
  padding: 2px 4px;
  align-items: flex-start;
  gap: 10px;
}

.tkdn-project-card {
  display: flex;
  flex: 1;

  margin: 0;
  width: 100%;
}

.tkdn-provision-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.tkdn-provision-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
}

@media only screen and (max-width: 960px) {
  .content-assessment-container {
    flex-direction: column;
  }
}
