.tkdn-benefit {
  flex: 1 0 30%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tkdn-benefit-img {
  aspect-ratio: 4/3;
  max-height: 200px;
  object-fit: cover;
}
