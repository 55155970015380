.branch-home {
    padding: 4rem;
    background: #fff;
    margin: auto;
    max-width: 1340px;
}

h1 {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
}

.border-title-branch {
    border-bottom: 4px solid #3A7AE3;
    width: 60px;
    position: absolute;
    left: 49.4%;
}

.title-branch {
    padding-top: 28px;
    justify-content: center;
    margin: auto;
}

.text-description {
    letter-spacing: 1px;
    padding-top: 8px;
    font-size: 16px;
}

#canvas {
    width: 100%;
}

.counter {
    padding-top: 108px;
    margin: auto;
}

.counter-col {
    margin: auto;
}

.count-up {
    color: #3A7AE3;
    font-size: 64px;
    font-weight: 600;
    justify-content: center;
}

.count-text {
    color: black;
    font-size: 24px;
    font-weight: 600;
    justify-content: center;
    letter-spacing: 1px;
}

.maps {
    padding: 48px 0 48px 0;
    justify-content: center;
    position: relative; 
    left: 0;
    top: 0;
}

@keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
}

@media screen and (max-width: 960px) {

    .title-branch {
        padding-top: 28px;
        letter-spacing: 1px;
        left: 1%;
        text-align: left;
        margin: auto;
    }

    h1 {
        font-size: 32px;
        font-weight: 600;
        text-align: center;
    }

    .border-title-branch {
        left: 49.4%;
    }

    .counter {
        padding-top: 0;
    }

    .count-text {
        color: black;
        font-size: 18px;
        font-weight: 600;
        justify-content: center;
        letter-spacing: 1px;
        text-align: center;
    }

    .count-up {
        color: #3A7AE3;
        padding: 10px 0 0 0;
        font-size: 32px;
        font-weight: 600;
        justify-content: center;
    }
}