video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.hero-container-vpti {
  /* background: url('/images/hero-banner-vpti.jpeg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  margin-top: -80px;
}

.hero-container-vpti-mobile {
  background: url("~/public/images/hero-banner-vpti.png") center center/cover
    no-repeat;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  object-fit: contain;
  margin-top: -80px;
}

.vid-container {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  z-index: -1;
}

.hero-container-vpti > h1 {
  color: white;
  font-size: 64px;
  /* margin-top: 100px; */
  padding-left: 240px;
  padding-right: 240px;
  text-align: center;
  letter-spacing: 0.1rem;
  max-width: 1340px;
}

.hero-container-vpti > p {
  color: white;
  font-size: 24px;
  margin-top: 30px;
  padding-left: 150px;
  padding-right: 150px;
  text-align: center;
  letter-spacing: 0.1rem;
  max-width: 1340px;
}

.hero-container-vpti-mobile > h1 {
  color: white;
  font-size: 64px;
  /* margin-top: 100px; */
  padding-left: 240px;
  padding-right: 240px;
  text-align: center;
  letter-spacing: 0.1rem;
  max-width: 1340px;
}

.hero-container-vpti-mobile > p {
  color: white;
  font-size: 24px;
  margin-top: 30px;
  padding-left: 150px;
  padding-right: 150px;
  text-align: center;
  letter-spacing: 0.1rem;
  max-width: 1340px;
}

.hero-btns {
  margin-top: 32px;
  align-items: center;
}

.hero-btns .btn {
  margin: 16px 16px 16px 0;
}

@media screen and (max-width: 960px) {
  .hero-container-vpti > h1 {
    font-size: 34px;
    /* margin-top: -150px; */
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0.1rem;
  }

  .hero-container-vpti > p {
    font-size: 18px;
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0.1rem;
  }

  .hero-container-vpti-mobile > h1 {
    font-size: 34px;
    /* margin-top: -150px; */
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0.1rem;
  }

  .hero-container-vpti-mobile > p {
    font-size: 18px;
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0.1rem;
  }
}

@media screen and (max-width: 768px) {
  .hero-container-vpti > h1 {
    font-size: 28px;
    /* margin-top: -150px; */
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0.1rem;
  }

  .hero-container-vpti > p {
    font-size: 14px;
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    letter-spacing: 0.1rem;
  }

  .hero-container-vpti-mobile > h1 {
    font-size: 28px;
    /* margin-top: -150px; */
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0.1rem;
  }

  .hero-container-vpti-mobile > p {
    font-size: 14px;
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    letter-spacing: 0.1rem;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
    align-items: center;
  }

  .btn {
    width: 100%;
  }
}
