.card {
    position: relative;
    width: 146px;
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 48px;
    border-radius: 0;
    -webkit-box-shadow: 4px 8px 32px 0 #B7B7B7;  
    -moz-box-shadow: 4px 8px 32px 0 #B7B7B7;  
    box-shadow: 0 4px 4px 0 #B7B7B7; 
}

.card:hover {
    -webkit-box-shadow: 4px 8px 32px 0 #B7B7B7;  
    -moz-box-shadow: 4px 8px 32px 0 #B7B7B7;  
    box-shadow: 4px 8px 32px 0 #B7B7B7;
}

.card-wrapper {
    width: 146px;
}

.card-img {
    height: 194px;
    width: 146px;
    border-radius: 0;
}

@media screen and (max-width: 960px) { 
    .card {
        width: 100px;
        margin-right: 15px;
        margin-left: 15px;
        margin-bottom: 28px;
    }

    .card-wrapper {
        width: 100px;
    }
    .card-img {
        height: 150px;
        width: 113px;
        border-radius: 0;
    }
}