.container-flow {
    padding: 0px 80px 0px 80px;
    background-color: #F2F2F2;
    max-width: 1340px;
    margin: auto;
}

.about-vpti {
    text-align: center;
    font-size: 16px;
    line-height: 160%;
}

.flow-vpti {
    padding: 80px 0 0 0;
}

.border-title-vpti {
    border-top: 4px solid #3A7AE3;
    width: 60px;
    position: absolute;
}

.title {
    font-size: 24px;
    font-weight: 600;
    padding-top: 16px;
    padding-bottom: 15px;
    margin-right: 32px;
    text-align: left;
}

.detail-flow-vpti {
    text-align: left;
    font-size: 16px;
    line-height: 160%;
}

.flow-img {
    padding: 80px 0 80px 0;
}

.img-vpti {
    max-width: 1200px;
}

.img-vpti-mobile {
    width: 100%;
}

.image-flow-vpti {
    max-width: 40em;
    display: block;
    margin: 0 auto;
}

.tab-content {
    width: 100%;
    padding: 20px;
    justify-content: center;
    align-items: stretch;
    display: flex;
    flex-wrap: nowrap;
}

/* .tab-content .vpti-content {
    background-color: #F2F2F2;
    justify-content: left;
    margin-left: 0;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 16px;
    min-width: 100%;
    height: 1787px;
    padding-top: 20px;
} */

.content-title {
    font-weight: 600;
    margin: 40px 0 40px 0;
    font-size: 24px;
}

@media screen and (max-width: 960px) {
    .container-flow {
        padding: 0px 40px 40px 40px;
    }

    .title {
        font-size: 20px;
    }

    .border-title-vpti {
        width: 50px;
    }

    .detail-flow-vpti {
        font-size: 14px;
    }

    .flow-img {
        /* padding: 20px 50px 50px 0; */
        margin: auto;
    }

    .img-vpti {
        margin: auto;
        width: 100%;
    }
}