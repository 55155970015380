.container-desc-tkdn {
  padding: 80px 80px 80px 80px;
  max-width: 1340px;
  margin: auto;
}

@media screen and (max-width: 960px) {
  .container-desc-tkdn {
    padding: 30px 40px 40px 40px;
  }

  .about-tkdn {
    font-size: 14px;
  }
}
