:root {
    --primary: #3A7Ae3;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #fff;
    border: 1px solid var(--primary);
    border-radius: 24rem;
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
    border-radius: 24rem;
}

.btn--white {
    background-color: white;
    color: black;
    padding: 8px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
    border-radius: 24rem;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--small {
    padding: 12px 26px;
    font-size: 14px;
    font-weight: 400;
}

.btn--xsmall {
    height: 43px;
    font-size: 14px;
    font-weight: 400;
    padding: auto;
}

.btn--medium:hover, 
.btn--large:hover{
    background: #fff;
    color: #242424;
    transition: 250ms;
}