.barcode-img {
  width: 120px;
  height: 120px;
  padding: 0;
}

.contact-detail {
  text-align: left;
  padding: 10px 0 0 0;
}

.whatsapp {
  font-weight: 400;
  color: black;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.containerWhatsapp {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.wrapperWhatsApp {
  display: flex;
  margin: 10px 0 0 0;
  gap: 4px
}

.whatsapp > p {
  margin: 0;
}