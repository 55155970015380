.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  background: transparent;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  font-weight: 600;

  --contentWidth: 1340px;
  padding: 1.2rem calc((100% - var(--contentWidth)) / 2);
}


.navbar.active {
  background: white;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100%;
  /* max-width: 1500px;
  width: 5000px; */
  /* background: pink; */
}

.navbar-logo {
  max-height: 40px;
  max-width: 124px;
  margin-top: -15px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  align-items: center;
  margin-right: 2rem;
}

.nav-item {
  height: 70px;
}

.nav-item-dd {
  position: relative;
}

.nav-item-dropdown {
  height: 70px;
  float: left;
  overflow: hidden;
}


.nav-item-toggle {
  margin: auto;
}

.nav-links {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links-fixed {
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links-dd {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  transition: background-color 0.3s;
}

.nav-links-dd:hover, .nav-links-dd.active, .nav-links-dd.selected {
  /* border-bottom: 4px solid #fff; */
  transition: all 0.2s ease-out;
  color: white;
  text-decoration: none;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white; 
  width: 220px;
  text-align: left;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
}

.nav-item-dd:hover .dropdown {
  display: block;
}

.sub-nav-item {
  list-style: none;
}

.sub-nav-item .nav-links-dd {
  padding: 10px 20px;
  text-decoration: none;
  color: #333; /* Adjust color as needed */
  display: block;
  transition: background-color 0.3s;
}

.sub-nav-item .nav-links-dd:hover, .sub-nav-item .nav-links-dd.active, .sub-nav-item .nav-links-dd.selected {
  background-color: #ddd; 
  /* color: #000;  */
}

.nav-links-dd.active {
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.sub-nav-item .nav-links-dd {
  padding: 10px 20px;
  text-decoration: none;
  color: #333; /* Adjust color as needed */
  display: block;
  transition: background-color 0.3s;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
  color: white;
  text-decoration: none;
}

.nav-links.active {
  /* border-bottom: 4px solid black; */
  /* transition: all 0.2s ease-out; */
  color: black;
  text-decoration: none;
}

.nav-links.active:hover {
  border-bottom: 4px solid black;
  transition: all 0.2s ease-out;
  color: black;
  text-decoration: none;
}

.nav-links-fixed:hover {
  border-bottom: 4px solid black;
  transition: all 0.2s ease-out;
  color: black;
  text-decoration: none;
}

.nav-links-fixed.active:hover {
  border-bottom: 4px solid black;
  transition: all 0.2s ease-out;
  color: black;
  text-decoration: none;
}

.selected {
  color: #3a7ae3;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.selected:hover {
  border-bottom: none;
  transition: all 0.2s ease-out;
  color: #3a7ae3;
  text-decoration: none;
}

.nav-items {
  color: white;
}

.nav-items.active {
  color: black;
}

.fa-bars {
  color: #fff;
}

.fa-bars.active {
  color: black;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 2rem;
  }

  .nav-menu.active {
    background: white;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: black;
  }

  .nav-links-dd {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: black;
  }

  .nav-links-fixed {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: black;
  }

  .nav-links.active {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: black;
  }

  .nav-links-fixed.active {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: black;
  }

  .nav-links-dd.active {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: black;
  }

  .nav-item-toggle {
    margin-top: 20px;
  }

  .nav-links:hover {
    color: black;
    text-decoration: none;
    border-bottom: none;
  }

  .nav-links.active:hover {
    color: black;
    text-decoration: none;
    border-bottom: none;
  }

  .nav-links-dd:hover {
    color: black;
    text-decoration: none;
    border-bottom: none;
  }

  .nav-links-dd.active:hover {
    color: black;
    text-decoration: none;
    border-bottom: none;
  }

  .nav-links-fixed:hover {
    color: black;
    text-decoration: none;
    border-bottom: none;
  }

  .nav-links-fixed.active:hover {
    color: black;
    text-decoration: none;
    border-bottom: none;
  }

  .selected {
    text-align: center;
    /* padding: 2rem; */
    width: 100%;
    display: table;
    color: #3a7ae3;
  }

  .selected:hover {
    border-bottom: none;
    transition: all 0.2s ease-out;
    color: #3a7ae3;
    text-decoration: none;
  }

  .navbar-logo {
    position: absolute;
    margin-top: -25px;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: white;
    font-size: 2rem;
  }

  .fa-times.active {
    color: black;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    transition: 250ms;
  }

  .hide-dropdown{
    display: none !important
  }

  .show-dropdown {
    display: block !important
  }

  .nav-item-dd:hover .dropdown {
    display: none;
  }

  .center {
    margin: 0 auto;
  }

  .dropdown {
    width: 100%;
  }
}
